import React from 'react'
import PricingItem from "./PricingItem";

function PricingBox({plan, data}) {
    return (
        <>
            <div className="lqd-column col-md-4">

            </div>
            {/* /.col-md-4 */}

            <div className="lqd-column col-md-4">
                <div className="pricing-table pricing-table-minimal">
                    <div className="pricing-table-header">

                        <p className="pricing">
                            <small>from </small><span>€ </span>{plan}<small></small>
                        </p>
                        <h5>Check with your account manager for more details!</h5>
                    </div>
                    {/* /.pricing-table-head */}
                    <PricingItem data={data}/>
                </div>
            </div>
            {/* /.col-md-4 */}

            <div className="lqd-column col-md-4">


            </div>
            {/* /.col-md-4 */}
        </>
    )
}

export default PricingBox