import React from 'react'
import '../../assets/css/themes/virtus-3.css'
import '../../assets/css/theme.min.css'
import '../../assets/img/misc/browser-bar.jpg'
import '../../assets/img/spinners/spinner-4-light.svg'
import '../../assets/img/spinners/spinner-1.gif'
import Logo from '../../assets/img/logo/aviareps-virtual-event-configurator-blue.png'


function MenuP() {


    return (
        <>
            <header className="main-header main-header-overlay bb-fade-black-005" data-sticky-header="true">

                <div className="mainbar-wrap">
                    <div className="megamenu-hover-bg"></div>

                    <div className="container-fluid mainbar-container">
                        <div className="mainbar">
                            <div className="row mainbar-row align-items-lg-stretch px-4">

                                <div className="col pr-5">
                                    <div className="navbar-header">
                                        <a className="navbar-brand pt-30 pb-30" href="/" rel="home">
										<span className="navbar-brand-inner">
											<img className="logo-sticky" src={Logo}
                                                 alt="AVIAREPS Virtual Event Configurator"/>
											<img className="mobile-logo-default" src={Logo}
                                                 alt="AVIAREPS Virtual Event Configurator"/>
											<img className="logo-default" src={Logo} alt="Virtual Event Configurator"/>
										</span>
                                        </a>
                                        <button type="button"
                                                className="navbar-toggle collapsed nav-trigger style-mobile"
                                                data-toggle="collapse" data-target="#main-header-collapse"
                                                aria-expanded="false"
                                                data-changeclassnames='{ "html": "mobile-nav-activated overflow-hidden" }'>
                                            <span className="sr-only">Toggle navigation</span>
                                            <span className="bars">
											<span className="bar"></span>
											<span className="bar"></span>
											<span className="bar"></span>
										</span>
                                        </button>
                                    </div>

                                </div>


                                <div className="col">

                                    <div className="collapse navbar-collapse" id="main-header-collapse">

                                        <ul id="primary-nav"
                                            className="main-nav nav align-items-lg-stretch justify-content-lg-center main-nav-hover-underline-1"
                                            data-submenu-options='{ "toggleType":"fade", "handler":"mouse-in-out" }'
                                            data-localscroll="true">

                                            <li className="is-active">
                                                <a href="#">
                                                    <span className="link-icon"></span>
                                                    <span className="link-txt">
													<span className="link-ext"></span>
													<span className="txt">Home</span>
												</span>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="#pricing">
                                                    <span className="link-icon"></span>
                                                    <span className="link-txt">
													<span className="link-ext"></span>
													<span className="txt">Sales Contact</span>
												</span>
                                                </a>
                                            </li>
                                        </ul>


                                    </div>


                                </div>


                                <div className="col text-right">

                                    <div className="header-module">


                                    </div>


                                </div>


                            </div>

                        </div>

                    </div>

                </div>


            </header>
        </>
    )

}

export default MenuP;
