import React from "react";

function AnalyticsBullets({bullets}) {

    return (
        <div className="liquid-progressbar liquid-progressbar-sm liquid-progressbar-circle"
             data-progressbar="true" data-progressbar-options='{"value":90,"suffix":"%"}'>
            <div className="liquid-progressbar-inner bg-progressbar-turkies-50">
									<span className="liquid-progressbar-bar">
										<span className="liquid-progressbar-percentage text-white"></span>
									</span>
            </div>
            {/* /.liquid-progressbar-inner */}
            <div className="liquid-progressbar-details">
                <h3 className="text-white" dangerouslySetInnerHTML={{__html: bullets.title}}></h3>
            </div>
            {/* /.liquid-progressbar-detail */}
        </div>


    )

}

export default AnalyticsBullets