import React from "react";

function FooterMP(){
    return(
        <section className="vc_row d-flex flex-wrap bg-turkies">

            {/* /.lqd-particles-bg-wrap */}

            <div className="container">
                <div className="row">



                    <div className="text-white text-center mt-30">
                            <p>Copyright 2021 | Virtual Event Configurator is a service of <a href="https://www.aviareps.com" title="Official AVIAREPS AG Website" target="_blank">AVIAREPS AG</a> .</p>
                    </div>

                </div>
                {/* /.row */}
            </div>
            {/* /.container */}

        </section>


    )
}
export default FooterMP