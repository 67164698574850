import React from 'react'
import Logo from '../../assets/img/logo/aviareps-virtual-event-configurator-blue.png'

function Footer(accountManagers) {

    return (
        <footer className="main-footer pt-50 pb-50" data-sticky-footer="true">

            <section>
                <div className="container">
                    <div className="row d-flex flex-wrap align-items-center">

                        <div className="lqd-column col-md-3 col-xs-12 text-center text-md-left mb-4 mb-md-0">
                            <figure>
                                <img src={Logo} alt="Virtus"/>
                            </figure>
                        </div>
                        {/* /.col-md-3 col-xs-12 */}
                        <div className="lqd-column col-md-5 col-xs-12 text-center mb-4 mb-md-0">

                            <ul className="lqd-custom-menu reset-ul inline-nav text-primary">
                                <li><a href="https://www.aviareps.com" title="Corporate website AVIAREPS"
                                       target="_blank">Corporate Website</a></li>
                                <li><a href="/imprint_content" title="AVIAREPS Imprint"
                                       target="_blank">Imprint</a></li>
                                <li><a href="/privacy_policy" title="Privacy Policy AVIAREPS"
                                       target="_blank">Data Protection</a></li>
                                <li><a href="/cookie_policy" title="CookiePolicy AVIAREPS"
                                       target="_blank">Cookie Policy</a></li>
                            </ul>
                        </div>
                        {/* /.col-md-3 col-xs-12 */}
                        <div className="lqd-column col-md-3 col-xs-12 text-center text-md-right">
                        </div>
                        {/* /.col-md-3 col-xs-12 */}

                    </div>
                    {/* /.row */}
                </div>
                {/* /.container */}
            </section>

        </footer>
    )
}


export default Footer