import React from "react";
import {LoadingBody, LoadingScreen} from "../styled";


function Loading(){
    return(
        <LoadingScreen>
            <LoadingBody>
                Your Presentation will be prepared....
            </LoadingBody>
        </LoadingScreen>
    )
}

export default Loading