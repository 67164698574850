import React from "react";
import LiveImg from "../../assets/img/ve-img/live-presentation-square.jpg";

function LiveFeatureImage(){

    return(
        <div className="lqd-column col-md-8 col-xs-12 visible-lg visible-md px-md-6">

            <figure className="mb-70">
                <img src={LiveImg} alt="Live Presentation Design"/>
            </figure>

        </div>

    )
}

export default LiveFeatureImage