import React from "react";
import RoundtableImg from '../../assets/img/ve-img/roundtable-panel-setup.jpg'

function Roundtable({data}) {

let presentation = data.presentation

    return (
        <section id="roundtable" className="vc_row pt-100 pb-100">
            <div className="container">
                <div className="row d-flex flex-wrap align-items-center">
                    <div className="lqd-column col-xs-12 mb-40">

                        <header className="fancy-title text-center mb-6">
                            <h3 className="mb-3 font-weight-bold ltr-sp-2 text-uppercase text-primary" dangerouslySetInnerHTML={{__html: presentation.bullet_title}}></h3>
                            <h5 className=" text-primary" dangerouslySetInnerHTML={{__html: presentation.product_info_body}}></h5>
                        </header>

                    </div>
                    <div className="lqd-column col-md-6">
                        {presentation.bullet_points.map(item =>(
                        <div
                            className="iconbox iconbox-side iconbox-circle iconbox-sm iconbox-icon-linked iconbox-heading-xs mb-50">
                            <div className="iconbox-icon-wrap">
									<span className="iconbox-icon-container">
										<span className="iconbox-icon-hover-bg bg-dark"></span>
										<i className="icon-et-presentation"></i>
									</span>
                            </div>
                            {/* /.iconbox-icon-wrap */}

                            <div className="contents">
                                <h3 className="font-weight-semibold text-primary text-uppercase" dangerouslySetInnerHTML={{__html: item.title}}></h3>
                                <p dangerouslySetInnerHTML={{__html: item.product_bullet_info}}></p>
                            </div>

                            {/* /.contents */}
                        </div>
                            ))}
                        {/* /.iconbox */}
                    </div>
                    {/* /.lqd-column col-md-6 */}

                    <div className="lqd-column col-md-6 text-md-right">

                        <figure>
                            <img src={RoundtableImg} style={{boxShadow: "3px 3px 3px 3px grey", borderRadius:"5px", opacity: "0.8"}} alt="Roundtable"/>
                        </figure>

                    </div>
                    {/* /.lqd-column col-md-6 */}

                </div>
                {/* /.row */}
            </div>
            {/* /.container */}
        </section>

    )
}

export default Roundtable