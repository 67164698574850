import React from "react";
import VEAnalytics from '../../assets/img/ve-img/virtual-event-analytics.jpg'
import '../../assets/css/custom-style.css'
import AnalyticsBullets from "./AnalyticsBullets";
function Analyticsfeature(props){

    const analyticsPresentation = props.data.presentation

    if(analyticsPresentation){
    return (

        <section id="analytics" className="vc_row bg-cover bg-center-bottom"
                 style={{backgroundColor: "#30333a"}}>
            <div className="container">
                <div className="row d-flex flex-wrap align-items-end">

                    <div className="lqd-column col-md-5 pt-100 visible-md visible-lg">

                        <div
                            className="liquid-img-group-container mb-0"
                            data-parallax="true"
                            data-parallax-from='{"translateX":-120}'
                            data-parallax-to='{"translateX":0}'
                            data-parallax-options='{"overflowHidden":false,"easing":"linear"}'>
                            <div className="liquid-img-group-inner">
                                <div className="liquid-img-group-single">
                                    <div className="liquid-img-group-img-container">
                                        <div className="liquid-img-container-inner">
                                            <figure>
                                                <img src={VEAnalytics} alt="Ultimate Design"/>
                                            </figure>
                                        </div>
                                        {/* /.liquid-img-container-inner */}
                                    </div>
                                    {/* /.liquid-img-group-img-container */}
                                </div>
                                {/* /.liquid-img-group-single */}
                            </div>
                            {/* /.liquid-img-group-inner */}
                        </div>
                        {/* /.liquid-img-group-container */}

                    </div>
                    {/* /.lqd-column col-md-5 */}

                    <div className="lqd-column col-md-7 pl-md-6 pt-100 pb-100">

                        <header className="fancy-title mb-7 pr-md-9 mr-md-5">
                            <h2 className="text-white" dangerouslySetInnerHTML={{__html: analyticsPresentation.bullet_title}}></h2>
                            <p className="text-white" dangerouslySetInnerHTML={{__html: analyticsPresentation.product_info_body}}></p>
                        </header>

                        {analyticsPresentation.bullet_points.map(bullet=> <AnalyticsBullets bullets={bullet}/>)}

                    </div>
                    {/* /.lqd-column col-md-7 */}

                </div>
                {/* /.row */}
            </div>
            {/* /.container */}
        </section>
    )}else{
        return null
    }
}

export default Analyticsfeature