import React, {useState, useEffect} from 'react';
import {QueryClient, QueryClientProvider, useQuery} from 'react-query'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom'
import Menu from './components/configurator/Menu'
import MainContent from "./components/configurator/MainContent";
import HeaderConfigurator from "./components/configurator/HeaderConfigurator";
import MenuP from './components/presentation/MenuP'
import MainContentP from "./components/presentation/MainContentP";
import Footer from './components/presentation/Footer'
import FooterMP from './components/configurator/FooterMP'
import Loading from "./components/Loading";
import Errormessage from './components/Errormessage'
import ThankYouPage from "./components/ThankYouPage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Imprint from "./components/Imprint";
import CookiePolicy from "./components/CookiePolicy";

const queryClient = new QueryClient()

function App() {

    //legal components
    const [legalContent, setLegalContent] = useState()

    useEffect(() => {
        fetch('https://products.aviareps.com/api/legal-content')
            .then(result => result.json())
            .then(content => setLegalContent(content))
    }, []);

    console.log(legalContent)

    const legalComponentsList = {
        privacy_policy: PrivacyPolicy,
        imprint_content: Imprint,
        cookie_policy: CookiePolicy
    }

    let items = []
    let path = []

    if (legalContent)
        legalContent.map((item) => {
            const componentName = item.field_component_name
            for (let key in legalComponentsList) {
                if (key === componentName) {
                    const RealComponent = legalComponentsList[key]
                    items.push(<RealComponent props={item}/>)
                }

            }
            path.push("/" + componentName)

        })

    return (
        <Router>
            <div id="wrap">
                <QueryClientProvider client={queryClient}>
                    <Switch>
                        <Route exact path="/">
                            <Menu/>
                            <HeaderConfigurator/>
                            <MainContent/>
                            <FooterMP/>
                        </Route>
                        <Route path="/presentation">
                            <MenuP/>
                            <MainContentP/>
                            <Footer/>
                        </Route>
                        <Route exec path="/thank-you">
                            <ThankYouPage/>
                        </Route>
                        <Route exec path={path}>
                            {items}
                        </Route>
                    </Switch>
                </QueryClientProvider>
            </div>
        </Router>


    )

}


export default App;
