import React from 'react'
import ChatBullet from "./ChatBullet";

function ProductDescription({product}) {

    return (
        <>
            {/*This content will be mapped from database*/}
            <div className="lqd-column col-xs-12 mb-40">
                <header className="fancy-title text-center mb-6">
                    <h3 className="mb-3 font-weight-bold ltr-sp-2 text-primary text-uppercase" dangerouslySetInnerHTML={{__html: product.bullet_title}}></h3>
                    <h5 className="text-primary" dangerouslySetInnerHTML={{__html: product.product_info_body}}></h5>
                </header>

            </div>
            {/* End of mapping */}

            {
                product.bullet_points.map(bullet => <ChatBullet bullet={bullet}/>)
            }
        </>

    )
}

export default ProductDescription