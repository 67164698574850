import React, {useState} from "react";

function QuestionsCounter(counter){


    return(
        <div className="counter ">
            <div className="counter-item font-size-1-25x">

                {counter.counter}/5</div>
        </div>
    )
}

export default QuestionsCounter