import React from "react";
import {LoadingBody, LoadingScreen} from "../styled";


function Error(){
    return(
        <LoadingScreen>
            <LoadingBody>
                Something went wrong...please try again later
            </LoadingBody>
        </LoadingScreen>
    )
}

export default Error