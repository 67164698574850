import React from 'react'
import ProductPoints from "./ProductPoints";

function ProductDescription(props){
    const communciationPresentation = props.presentation
   if(communciationPresentation) {
       return (
           <section id="communication" className="vc_row pt-70 pb-70">
               <div className="container">
                   <div className="row d-flex flex-wrap align-items-center">
                       <ProductPoints product={communciationPresentation}/>
                   </div>
                   {/* /.row */}
               </div>
               {/* /.container */}
           </section>
       )
   }else{
       return null
   }
}

export default ProductDescription