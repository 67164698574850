import React from 'react'

function Exhibitionhalldetails() {

    return (
        <section id="exhibition" className="vc_row pt-80 pb-80 bg-cover" data-parallax="true"
                 data-parallax-options='{ "parallaxBG": true }'
                 style={{backgroundColor: "#5a5a5a"}}>

            <div className="container">
                <div className="row">

                    <div className="lqd-column col-md-8 col-md-offset-2 text-center">

                        <h2 className="text-white mt-0 mb-4">Exhibition Hall</h2>
                        <p className="font-size-18 text-fade-white-07 px-md-7 mx-md-3 mb-5">In order to host your
                            exhibitors, we can offer to you the exhibition hall, to showcase the products of your
                            partners.</p><p className="font-size-18 text-fade-white-07 px-md-7 mx-md-3 mb-5"> Our solution offers fully customizable and brandable booths to present your
                        exhibitor’s products and their brands.</p>

                        <a href="#" target="_blank" className="btn btn-solid circle btn-bordered border-thin mb-2">
								<span>
									<span className="btn-txt">Examples</span>
								</span>
                        </a>

                    </div>
                    {/* /.col-md-8 col-md-offset-2 */}

                </div>
                {/* /.row */}
            </div>
            {/* /.container */}

        </section>
    )
}

export default Exhibitionhalldetails