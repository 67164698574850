import React from 'react'
import PricingBox from './PricingBox'


function PricingSection(data) {

    //price calculation
    const componentPrice = data.data.presentation
    const priceValues = componentPrice.map(priceItem => (priceItem.field_component_price_export));

    //transform array of strings to array of numbers
    const priceListInt = priceValues.map((i) => Number(i));
    console.log(priceListInt)
    //calculate array to total number
    let pricingPlan = 0;
    let eventTypeDevider = 2
    if(priceListInt.pop()=== 1){
        priceListInt.splice(-1)
        priceListInt.forEach((element) => pricingPlan += element)
    }else{
        priceListInt.forEach((element) => pricingPlan += element)
        pricingPlan = pricingPlan/eventTypeDevider
    }





    return (
        <section id="pricing" className="vc_row pt-100 pb-110 bg-light">

            <div className="container">

                <div className="row">

                    <div className="lqd-column col-md-6 col-md-offset-3">

                        <header className="fancy-title mb-70 text-center">
                            <h2 className="text-primary">Based on your selected elements your plan will be</h2>
                        </header>
                        {/* /.fancy-title text-center */}

                    </div>
                    {/* /.col-md-6 col-md-offset-3 */}

                </div>
                {/* /.row */}

                <div className="row">

                    <PricingBox data={data} plan={pricingPlan}/>

                </div>
                {/* /.row */}

            </div>
            {/* /.container */}

        </section>
    )

}

export default PricingSection