import styled from "styled-components";
import {device} from "./device";

export const HeaderContent = styled.div`
  @media ${device.desktop} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100%

  }
`

export const HeaderTitle = styled.h1`
  @media ${device.desktop} {
    font-size: 5rem;
    color: #000f40;
    font-weight: bold;
    text-align: center;
  }
`

export const HeaderSubtitle = styled.h3`
  @media ${device.desktop} {
    font-size: 2.6rem;
    color: #000f40;
    text-align: center;
  }`


export const CompanyTitle = styled.h1`
  @media ${device.desktop} {
    color: #23a8ba;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 5rem;
    text-align: center;
  }
`

export const ConfiguratorIntro = styled.h1`
  @media ${device.desktop} {
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 3rem;
    text-align: center;
    width: 75%;
  }
`

export const ImportantNote = styled.p`
  @media ${device.desktop} {
    font-size: 1.5rem;
    color: #5a5a5a;
    font-style: italic;
    text-align: center;
    width: 85%;
  }

`
{/*Loading Screen input during fetching*/
}
export const LoadingScreen = styled.section`
  @media ${device.desktop} {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const LoadingBody = styled.h1`
  @media ${device.desktop} {
    color: #23a8ba;
    font-weight: bold;
    font-size: 5rem;
    text-align: center;
  }
`
export const LegalContent = styled.div`{
@media${device.desktop} {
  height: 150px;
  width: 300px;
} @media${device.tablet} {
  height: 100px;
  width: auto;
  margin: 90px 0px 30px 0px;
}
}`
export const CheckBox = styled.input`
@media${device.desktop} {
  width: 26px;
  height: 26px;
  float: left;
} @media${device.mobileM} {
  width: 26px;
  height: 26px;
  float: left;
}
`

export const CheckBoxLabel = styled.label`
@media${device.desktop} {
  font-size: 1.2rem;
  color: #fff;
  font-weight: normal;
  padding-left: 5px;
  letter-spacing: 1.5px;

} @media${device.mobileM} {
  font-size: 1rem;
}
`

// Question Button

export const ButtonBox = styled.div`
{
@media${device.desktop} {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 200px;
} @media${device.tablet} {
  align-items: center
}
}`


export const SubmissionButton = styled.button`{
@media${device.desktop}{
  width: 50%;
  height: 50px;
  background-color: rgba(90, 90, 90, 1);
  color: white;
  border-radius: 8px;
  z-index: 1;
  border: none;
  margin: 0px 0px 0px 10px;
  transition: 1000ms ease-out;
}
  &:hover{
    width: 70%;
    height: 70px;
    background-color: white;
    color: black;
  }

}`

export const FormBox = styled.div`{
  @media${device.desktop}{
  display: flex;
  flex-direction:row;
  width: 100%;
  height: 90px;
  float: left;
}
  @media${device.tablet}{
  justify-content: space-around;
}
}`