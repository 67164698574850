import React, {useState, useEffect} from 'react'
import Questionbox from './Questionbox'
import '../../assets/css/theme.min.css'
import '../../assets/css/themes/wave.css'
import '../../assets/vendors/liquid-icon/liquid-icon.min.css'
import '../../assets/vendors/font-awesome/css/font-awesome.min.css'
import '../../assets/css/theme-vendors.css'
import QuestionsCounter from "./QuestionsCounter";
import Form from "./Form";

function MainContent() {

    const [products, setProducts] = useState()
    const [answerSelection, setAnswerSelection] = useState(["19","22"])
    useEffect(() => {
        fetch("https://products.aviareps.com/api/product/0dddb26c-6d23-4e92-8b53-8a02f1d3d11c?_format=json")
            .then(res => res.json())
            .then(result => {
                setProducts(result)
            })
    }, [])


    return (


        <main id="content" className="content" data-liquid-stack="true"
              data-stack-options='{"navigation":false,"prevNextButtons":true,"pageNumber":true,"prevNextLabels":{"prev":"Previous","next":"Next"},"effect":"fadeScale", "disableOnMobile": false}'>

            {/*<QuestionsCounter/>*/}
            <Questionbox setSelection={setAnswerSelection} selection={answerSelection} products={products}/>
            <Form userSelection={answerSelection}/>
        </main>
    )
}

export default MainContent