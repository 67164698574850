import React, {useState, useEffect} from 'react'
import {useQuery} from "react-query";

import '../../assets/css/themes/virtus-3.css'
import '../../assets/css/theme.min.css'
import MainHeader from "./MainHeader";
import ProductDescription from "./ProductDescription";
import CallToActionSection from "./CallToActionSection";
import PricingSection from './PricingSection'
import Architecturecontent from "./Architecturecontent";
import Exhibitionhalldetails from "./Exhibitionhalldetails";
import Livefeature from "./Livefeature";
import Roundtable from "./Roundtable";
import Boothdetails from "./Boothdetails";
import Analyticsfeature from "./Analyticsfeature";
import Loading from "../Loading";
import Errormessage from "../Errormessage";

function MainContentP() {



    //set data request for presentation
    const [selectedData, setSelectedData] = useState([]);

    const userSearchString = window.location.search

    //store selected data of user in variable
    const items = []
    //defines local component names for rendering
    const presentationFields = {
        presentation_header: MainHeader,
        communicate_all_attendees: ProductDescription,
        exhibitor_booth: Boothdetails,
        live_presentation: Livefeature,
        roundtable_panel: Roundtable,
        architecture_landscape: Architecturecontent,
        event_performance: Analyticsfeature
    }

    //*fetching data from user profile
    const {isLoading, error, data} = useQuery('presentationData', () =>
        fetch(`https://products.aviareps.com/api/presentation/${userSearchString}`)
            .then(res => res.json())
            .then(response => setSelectedData(response.data)),
    )


    if (isLoading) return <Loading/>

    if (error) return <Errormessage/>

    selectedData.presentation.map((databaseComponent) => {
        const myProps = {
            meta: selectedData.meta,
            presentation: databaseComponent,
        }


        for (let currentKey in presentationFields) {
            if (currentKey === databaseComponent.component_name) {
                const RealComponentName = presentationFields[currentKey]
                items.push(<RealComponentName data={myProps}/>)
            }
        }

    })


    return (
        <main id="content" class="content">
            {items}
            <PricingSection data={selectedData} />
        </main>

    )
}

export default MainContentP