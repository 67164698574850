import React from "react";
import {CompanyTitle, HeaderContent, HeaderTitle, ImportantNote} from "../styled";
import FooterMP from "./configurator/FooterMP";

function PrivacyPolicy(props){

    return (
        <section className="vc_row fullheight">
            <HeaderContent>
                <HeaderTitle dangerouslySetInnerHTML={{__html: props.props.title}}></HeaderTitle>
                <ImportantNote dangerouslySetInnerHTML={{__html: props.props.field_body}}></ImportantNote>
            </HeaderContent>
        </section>

    )
}

export default PrivacyPolicy