import React from 'react';
import '../assets/css/themes/virtus-3.css';
import '../assets/css/theme.min.css';
import Menu from "./configurator/Menu";
import FooterMP from "./configurator/FooterMP";
import {HeaderContent, HeaderTitle, CompanyTitle, ImportantNote} from "../styled";


function ThankYouPage() {

    return (
<>
        <Menu/>
        <section className="vc_row fullheight">
            <HeaderContent>
                <HeaderTitle>AVIAREPS <br/>Virtual Event Presentation</HeaderTitle>
                <CompanyTitle>Your presentation is currently being created</CompanyTitle>
                <ImportantNote>Check your Emails to get your personal access!</ImportantNote>
            </HeaderContent>
        </section>
    <FooterMP/>
</>
    )
}

export default ThankYouPage