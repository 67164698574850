import React from "react";
import {ConfiguratorIntro, HeaderContent, HeaderTitle, ImportantNote} from "../../styled";

function HeaderConfigurator() {

    return (
        <section className="vc_row fullheight d-flex flex-wrap align-items-center py-5 py-md-0 bg-turkies">
            <HeaderContent>
                <HeaderTitle>AVIAREPS <br/>Virtual Event Configurator</HeaderTitle>
                <ConfiguratorIntro>CHOOSE YOUR VIRTUAL EVENT OPTIONS BELOW AND WE WILL CREATE AN INDIVIDUAL PRESENTATION
                    FOR YOU!
                    YOU WILL RECEIVE YOUR TAILORED OFFER IN LESS THAN ONE MINUTE!</ConfiguratorIntro>
                <ImportantNote>The options below are core features we provide. For additional features and individual
                    special requests please check with your regional account manager. You’ll find the contact details at
                    the end of your individual offer.</ImportantNote>
                <div><i className="icon-arrows_down_double-34" style={{fontSize: "5rem", color: "#fff"}}></i></div>
            </HeaderContent>

        </section>

    )

}

export default HeaderConfigurator