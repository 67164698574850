import React, {useState} from 'react'
import {withRouter, useHistory} from 'react-router-dom';
import {CheckBoxLabel, CheckBox, LegalContent, SubmissionButton, ButtonBox, FormBox} from "../../styled";

function Form({userSelection}) {

    //redirect to thank you page for user after submission
    let history = useHistory();

    function handleRedirect() {
        history.push("/thank-you");
    }

    //handle submission
    const [request, setRequest] = useState()

    function handleSelection(e) {
        e.preventDefault()
        let newClient = {
            firstname: document.getElementById('firstName').value,
            lastname: document.getElementById('lastName').value,
            companyName: document.getElementById('companyName').value,
            email: document.getElementById('email').value,
            country: document.getElementById('country').value,
            selectedItems: userSelection,
            formID: "virtual-event-configurator",
            productID: "0dddb26c-6d23-4e92-8b53-8a02f1d3d11c"
        }


        postData('https://products.aviareps.com/api/request', newClient)
        //document.querySelector('form').reset()
        handleRedirect()
    }


    async function postData(postUrl = 'https://products.aviareps.com/api/request', data = {}) {
        // Default options are marked with *
        const response = await fetch(postUrl, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'text/plain',
            },
            redirect: 'follow', // manual, *follow, error
            //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        return response.json(); // parses JSON response into native JavaScript objects

    }


    return (
        <section className="vc_row fullheight d-flex flex-wrap align-items-center py-5 py-md-0 bg-turkies">

            <div className="lqd-particles-bg-wrap">

                <div className="ld-particles-container">
                    <div
                        className="ld-particles-inner"
                        id="ld-particles-4"
                        data-particles="true"
                        data-particles-options='{"particles":{"number":{"value":6},"color":{"value":["#ffb739","#fe045e","#3de4a3","#aa57ff"]},"shape":{"type":["circle"]},"opacity":{"random":true,"anim":{"enable":true,"opacity_min":0.6,"speed":1,"sync":true}},"size":{"value":3,"random":true,"anim":{"enable":true,"size_min":2,"speed":1}},"move":{"out_mode":"out"}},"interactivity":[]}'></div>
                </div>

            </div>
            {/* /.lqd-particles-bg-wrap */}

            <div className="container">
                <div className="row">

                    <div className="lqd-column col-md-8 col-md-offset-2">

                        <div className="lqd-promo-wrap text-white">
                            <div className="lqd-promo-inner">

                                <div className="lqd-promo-dynamic-shape">
                                    <svg className="scene" width="100%" height="100%" fill="rgba(0, 0, 0, 0.05)"
                                         viewBox="0 0 650 650">

                                    </svg>
                                </div>
                                {/* /.lqd-promo-dynamic-shape */}

                                <div className="lqd-promo-cat">

                                </div>
                                {/* /.lqd-promo-cat */}

                                <div className="lqd-promo-img">
                                    <div className="button-box">
                                        <form onSubmit={handleSelection}>
                                            <FormBox>
                                                <input required id="firstName" className="form-button"
                                                       placeholder="*FirstName"
                                                       type="text"
                                                       onChange={event => setRequest(event.target.value)}
                                                       name="Firstname
                                                       "/>
                                                <input required id="lastName" className="form-button"
                                                       placeholder="*LastName"
                                                       type="text"
                                                       onChange={event => setRequest(event.target.value)}
                                                       name="Lastname"/>
                                            </FormBox>
                                            <FormBox >
                                                <input required id="companyName" className="form-button"
                                                       placeholder="*Company"
                                                       type="text"
                                                       onChange={e => setRequest(e.target.value)}
                                                       name="Company"/>
                                                <input required id="email" className="form-button" placeholder="*Email"
                                                       type="email"
                                                       onChange={e => setRequest(e.target.value)}
                                                       name="Email"/>
                                            </FormBox>
                                            <FormBox >
                                                <select required name="country" id="country"
                                                        className="form-button-select">
                                                    <option value="">Your Company Country?</option>
                                                    <option key="DE" value="DE">Germany</option>
                                                    <option key="NL" value="NL">Netherlands</option>
                                                    <option key="IT" value="IT">Italy</option>
                                                    <option key="HU" value="HU">Hungary</option>
                                                    <option key="BG" value="BG">Bulgaria</option>
                                                    <option key="RO" value="RO">Romania</option>
                                                    <option key="MD" value="MD">Moldova</option>
                                                    <option key="DE" value="DE">Other Country</option>
                                                </select>

                                            </FormBox>


                                            <div className="lqd-promo-img">

                                                <LegalContent>
                                                    <CheckBox required name="gdpr" className="form-button"
                                                              type="checkbox"/>
                                                    <CheckBoxLabel htmlFor="gdpr">I do understand and accept, that the
                                                        configurator will store my submitted data for the purpose to
                                                        provide
                                                        me an customized presentation.</CheckBoxLabel>
                                                </LegalContent>

                                                <ButtonBox>
                                                    <SubmissionButton>Generate your presentation
                                                    </SubmissionButton>
                                                </ButtonBox>
                                            </div>
                                        </form>
                                    </div>
                                    {/* /.lqd-promo-img-inner */}
                                </div>
                                {/* /.lqd-promo-img */}

                                <div className="lqd-promo-content">
                                    <h2
                                    >final step</h2>
                                    <p
                                    >we will send you a branded presentation for your personal use </p>

                                </div>
                                {/* /.lqd-promo-content */}

                            </div>
                            {/* /.lqd-promo-inner */}

                        </div>
                        {/* /.lqd-promo-wrap */}

                    </div>
                    {/* /.col-md-8 col-md-offset-2 */}

                </div>
                {/* /.row */}
            </div>
            {/* /.container */}

        </section>

    )
}

export default withRouter(Form)

