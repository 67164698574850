import React from "react";
import Watch from '../../assets/demo/misc/watch-2.jpg'
import LiveFeatureImage from "./LiveFeatureImage";
import LiveFeatureBullets from "./LiveFeatureBullets";

function Livefeature(props) {
const livePresentation = props.data.presentation

    if(livePresentation) {
        return (
            <section id="livepresentation" className="vc_row pt-70 pb-70">
                <div className="container">
                    <div className="row d-flex flex-wrap align-items-center">

                        <div className="lqd-column col-xs-12 mb-40">

                            <header className="fancy-title text-center mb-6">
                                <h3 className="mb-3 font-weight-bold ltr-sp-2 text-uppercase text-primary" dangerouslySetInnerHTML={{__html: livePresentation.bullet_title}}></h3>
                                <h5 className=" text-primary" dangerouslySetInnerHTML={{__html: livePresentation.product_info_body}}></h5>
                            </header>
                        </div>
                        {/* /.lqd-column col-md-6 col-md-offset-3 */}


                        <div className="lqd-column col-md-4 col-xs-12">


                            {/* /.iconbox */}

                            {livePresentation.bullet_points.map(bullet => <LiveFeatureBullets LiveBullet={bullet} description={livePresentation}/>)}

                        </div>
                        {/* /.lqd-column col-md-4 col-xs-12 */}


                        <LiveFeatureImage/>
                        {/* /.lqd-column col-md-4 col-xs-12 */}

                        <div className="lqd-column col-md-4 col-xs-12">


                        </div>
                        {/* /.lqd-column col-md-4 col-xs-12 */}

                    </div>
                    {/* /.row */}
                </div>
                {/* /.container */}
            </section>
        )
    }else{
        return null
    }
}

export default Livefeature