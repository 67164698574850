import React, {useEffect, useState} from "react";

function PricingItem(data) {
    const clientData = data.data
    const clientMeta = clientData.data.meta
    const clientHomebase = clientMeta.country


//get Account Manager data
    const [accountManagers, setAccountManagers] = useState()

    useEffect(() => {
        fetch('https://products.aviareps.com/api/crm/account-manager-list')
            .then(response => response.json())
            .then(managers => {
                setAccountManagers(managers.data)
            })
    }, []);

    if (accountManagers) {
        let myAccountManager
        accountManagers.map((manager) => {
                if (manager.product === "virtual_events" && manager.country.includes(clientHomebase)) {
                    myAccountManager = manager
                }
            }
        )
        if (myAccountManager) {

            const phone = "tel: " + myAccountManager.phone
            const email = "mailto: " + myAccountManager.email
            return (
                <div>
                    <h1 className="text-primary">
										<span>
                                            <h5 className="text-primary">Your manager is</h5>
											<span className="btn-txt">{myAccountManager.display_name}</span>
										</span>
                    </h1>

                    <h1 className="text-primary">
                        <a href={email}
                           className="btn btn-solid text-uppercase circle btn-bordered border-thick fresco font-size-12 lh-15 font-weight-bold ltr-sp-2 px-2">
										<span>
											<span className="btn-txt">Send Email</span>
										</span>
                        </a></h1>
                    <h1 className="text-primary">
                        <a href={phone}
                           className="btn btn-solid text-uppercase circle btn-bordered border-thick fresco font-size-12 lh-15 font-weight-bold ltr-sp-2 px-2">
										<span>
											<span className="btn-txt">give a call</span>
										</span>
                        </a></h1>
                </div>
            )
        }
    }
    return (
        <div>
            <h1 className="text-primary"><span className="btn-txt">Searching for your account manager</span></h1>
        </div>
    )
}

export default PricingItem