import React from 'react'
import Architecture from '../../assets/img/ve-img/architecture.jpg'

function Architecturecontent(props) {

    const architecturePresentation = props.data.presentation
    return (
        <section id="architecture" className="vc_row pt-100 pb-100 bb-fade-black-005">

            <div className="container">
                <div className="row d-flex flex-wrap align-items-center">

                    <div className="lqd-column col-md-6 mb-4 mb-md-0">

                        <figure>
                            <img src={Architecture} style={{boxShadow: "3px 3px 3px 3px grey", borderRadius:"5px", opacity: "0.8"}} alt="Macbook"/>
                        </figure>

                    </div>
                    {/* /.col-md-6 */}

                    <div className="lqd-column col-md-6 pl-md-6 pr-md-7">

                        <header className="fancy-title mb-35">
                            <h2 className="mt-0 mb-4 pr-md-5 text-primary" dangerouslySetInnerHTML={{__html: architecturePresentation.bullet_title}}></h2>
                            <p dangerouslySetInnerHTML={{__html: architecturePresentation.product_info_body}}></p>
                        </header>

                    </div>
                    {/* /.col-md-6 */}

                </div>
                {/* /.row */}
            </div>
            {/* /.container */}

        </section>
    )

}

export default Architecturecontent