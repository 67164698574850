import React from 'react'
import '../../assets/css/theme.css'
import '../../assets/css/themes/wave.css'
import '../../assets/vendors/liquid-icon/liquid-icon.min.css'
import '../../assets/vendors/font-awesome/css/font-awesome.min.css'
import '../../assets/css/theme-vendors.css'
import '../../assets/css/theme-scheme-light.css'
import '../../assets/css/theme-shop.css'
import Logo from '../../assets/img/logo/aviareps-virtual-event-configurator.png'
import LogoDark from '../../assets/img/logo/aviareps-virtual-event-configurator-blue.png'


function Menu() {
    return (
        <>
            <header className="main-header header-fullscreen header-fullscreen-style-1 main-header-overlay">

                <div className="mainbar-wrap">
                    <div className="megamenu-hover-bg"> </div>
                    {/*megamenu*/}
                    <div className="container-fluid mainbar-container">
                        <div className="mainbar">
                            <div className="row mainbar-row align-items-lg-stretch px-4">

                                <div className="col">
                                    <div className="navbar-header">
                                        <a className="navbar-brand" href="#" rel="home">
											<span className="navbar-brand-inner">
												<img className="logo-dark" src={LogoDark}
                                                     alt="AVIAREPS Virtual Event Configurator "/>
												<img className="logo-light" src={Logo}
                                                     alt="AVIAREPS Virtual Event Configurator"/>
												<img className="logo-sticky" src={Logo}
                                                     alt="AVIAREPS Virtual Event Configurator"/>
												<img className="mobile-logo-default"
                                                     src={LogoDark}
                                                     alt="AVIAREPS Virtual Event Configurator"/>
												<img className="logo-default" src={Logo}
                                                     alt="AVIAREPS Virtual Event Configurator"/>
											</span>
                                        </a>

                                    </div>
                                    {/* /.navbar-header */}
                                </div>
                                {/* ./col */}

                                <div className="col text-right">
                                    <div className="header-module">

                                    </div>
                                    {/* /.header-module */}


                                    {/* /.collapse */}

                                </div>
                                {/* /.col */}

                            </div>
                            {/* /.mainbar-row */}
                        </div>
                        {/* /.mainbar */}
                    </div>
                    {/* /.mainbar-container */}
                </div>
                {/* /.mainbar-wrap */}

            </header>
            {/* /.main-header */}

        </>

    )
}

export default Menu
