import React from 'react'

function QuestionButton(props) {
        const buttonClasses = (props.isActive === props.name) ? "active butten-flex" : "button-flex";
        return (
            <button name={props.name} key={props.answer.product_tag.id} onClick={props.setIsActive} className={buttonClasses}
                    value={props.answer.product_tag.id} dangerouslySetInnerHTML={{__html: props.answer.answer_info}}></button>
        )

}
export default QuestionButton