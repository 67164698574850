import React, {useState} from 'react'
import '../../assets/css/theme.min.css'
import '../../assets/css/themes/wave.css'
import '../../assets/vendors/liquid-icon/liquid-icon.min.css'
import '../../assets/vendors/font-awesome/css/font-awesome.min.css'
import '../../assets/css/theme-vendors.css'
import '../../assets/vendors/bootstrap/css/bootstrap.css'
import '../../assets/css/custom-style.css'
import QuestionButton from "./QuestionButton";
import {ButtonBox} from "../../styled";

function Questionbox({products, setSelection, selection}) {

    const [presentation, setPresentation] = useState([])
    const [isActive, setIsActive] = useState("");
    const questionButtonHandler = (event) => {
        const clickedButtonName = event.target.attributes.name.value;

        //store selection
        const selectionButtonValue = event.target.value;
        setSelection([...selection.concat(selectionButtonValue)]);
        console.log(selection)

        /*// You can access all other attributes of the QuestionButton-component

        const attributeList = event.target.attributes;
        const availableAttributes = [];

        for(let index=0;index < attributeList.length; index++){
            const attributeName = attributeList.item(index).localName;
            const attributeValue = attributeList.item(index).value;

            const keyValuePair = {};
            keyValuePair[attributeName] = attributeValue;
            availableAttributes.push(keyValuePair);
        }
        alert('You have access to all attributes: ' + JSON.stringify(availableAttributes));
    */

        // Call the passed in method
        setIsActive(clickedButtonName);
    }


    if (!products) {
        return (<h1>Loading...</h1>)
    } else if (products) {
        const configProject = products[0]

        return (

            configProject.product_questions.map(item =>

                <section key="3"
                         className="vc_row fullheight d-flex flex-wrap align-items-center py-5 py-md-0 bg-turkies">

                    <div className="lqd-particles-bg-wrap">

                        <div className="ld-particles-container">
                            <div
                                className="ld-particles-inner"
                                id="ld-particles-1"
                                data-particles="true"
                                data-particles-options='{"particles":{"number":{"value":6},"color":{"value":["#ffb739","#fe045e","#3de4a3","#aa57ff"]},"shape":{"type":["circle"]},"opacity":{"random":true,"anim":{"enable":true,"opacity_min":0.6,"speed":1,"sync":true}},"size":{"value":3,"random":true,"anim":{"enable":true,"size_min":2,"speed":1}},"move":{"out_mode":"out"}},"interactivity":[]}'>

                            </div>
                        </div>

                    </div>
                    {/* /.lqd-particles-bg-wrap */}

                    <div className="container">
                        <div className="row">

                            <div className="lqd-column col-md-8 col-md-offset-2">

                                <div className="lqd-promo-wrap text-white">
                                    <div className="lqd-promo-inner">

                                        <div className="lqd-promo-dynamic-shape" data-dynamic-shape="true">
                                            <svg className="scene" width="100%" height="100%" fill="rgba(0, 0, 0, 0.05)"
                                                 viewBox="0 0 650 650">

                                            </svg>
                                        </div>
                                        {/* /.lqd-promo-dynamic-shape */}

                                        <div className="lqd-promo-cat">
                                            <ul className="reset-ul">
                                                <li>Question</li>
                                            </ul>
                                        </div>
                                        {/* /.lqd-promo-cat */}
                                        <div className="lqd-promo-img">
                                            <ButtonBox>
                                                {item.product_question_answers.map((answer) => (
                                                    <QuestionButton name={answer.answer_info} answer={answer}
                                                                    isActive={isActive}
                                                                    setIsActive={questionButtonHandler}/>
                                                ))
                                                }
                                            </ButtonBox>
                                            {/* /.lqd-promo-img-inner */}
                                        </div>
                                        {/* /.lqd-promo-img */}

                                        <div className="lqd-promo-content">
                                            <h2 key={item.id}
                                                className="text-white">{item.question_label}</h2>
                                            <p>{item.question_info}</p>
                                            <div><i className="icon-arrows_down_double-34"
                                                    style={{fontSize: "5rem", color: "#fff"}}></i></div>
                                        </div>
                                        {/* /.lqd-promo-content */}

                                    </div>
                                    {/* /.lqd-promo-inner */}
                                </div>
                                {/* /.lqd-promo-wrap */}

                            </div>
                            {/* /.col-md-8 col-md-offset-2 */}

                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}


                </section>
            )

        )

    }
}


export default Questionbox