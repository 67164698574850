import React from "react";

function ChatBullet({bullet}) {

    const myClassname = "icon-et-"+ bullet.bullet_point_icon_key
    return (
        <div key={bullet.title} className="lqd-column col-md-4 col-sm-6 mb-30">

            <div className="iconbox iconbox-side iconbox-circle iconbox-sm">
                <div className="iconbox-icon-wrap">
									<span className="iconbox-icon-container">
										<span className="iconbox-icon-hover-bg bg-dark"> </span>

                                        <i className={myClassname}> </i>
									</span>
                </div>
                {/* /.iconbox-icon-wrap */}
                <div className="contents">
                    <h3 className="font-size-15 font-weight-semibold text-uppercase text-primary" dangerouslySetInnerHTML={{__html: bullet.title}}></h3>
                    <p dangerouslySetInnerHTML={{__html: bullet.product_bullet_info}}></p>
                </div>
                {/* /.contents */}
            </div>

            {/* /.iconbox */}
        </div>
    )

}

export default ChatBullet