import React from 'react';
import '../../assets/css/themes/virtus-3.css';
import '../../assets/css/theme.min.css';

import {HeaderContent, HeaderTitle, HeaderSubtitle, CompanyTitle, ImportantNote} from "../../styled";

function MainHeader(props) {

const headerMeta = props.data.meta
    return (

        <section className="vc_row fullheight">
            <HeaderContent>
                <HeaderTitle>AVIAREPS <br/>Virtual Event Presentation for</HeaderTitle>
                <CompanyTitle>{headerMeta.companyName}</CompanyTitle>
                <ImportantNote>the selected elements and pricing in this presentation may differ from the final
                    offer</ImportantNote>
            </HeaderContent>
        </section>

    )
}

export default MainHeader