import React from "react";
import {CompanyTitle, HeaderContent, HeaderTitle, ImportantNote} from "../styled";
import FooterMP from "./configurator/FooterMP";

function Imprint(props){
    return (
        <section className="vc_row fullheight">
            <HeaderContent>
                <HeaderTitle>{props.title}</HeaderTitle>
                <ImportantNote>{props.field_body}</ImportantNote>
            </HeaderContent>
        </section>

    )
}

export default Imprint