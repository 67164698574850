import React from "react";
import Hall from '../../assets/img/ve-img/booth-square-image.jpg'
import BoothBullets from "./BoothBullets";

function Boothdetails(props) {
const boothPresentation = props.data.presentation

    if(boothPresentation){return (
        <section id="booth" className="vc_row pt-70 pb-70">
            <div className="container">
                <div className="row d-flex flex-wrap align-items-center">

                    <div className="lqd-column col-xs-12 mb-40">

                        <header className="fancy-title text-center mb-6">
                            <h3 className="mb-3 font-weight-bold ltr-sp-2 text-uppercase text-primary" dangerouslySetInnerHTML={{__html: boothPresentation.bullet_title}}></h3>
                            <h5 className=" text-primary" dangerouslySetInnerHTML={{ __html: boothPresentation.product_info_body }}></h5>
                        </header>

                    </div>
                    {/* /.lqd-column col-md-6 col-md-offset-3 */}

                    <div className="lqd-column col-md-4 col-xs-12">

                        {boothPresentation.bullet_points.map(bullet => <BoothBullets bullet={bullet}/>)}
                    </div>
                    {/* /.lqd-column col-md-4 col-xs-12 */}

                    <div className="lqd-column col-md-8 col-xs-12 visible-lg visible-md px-md-6">

                        <figure className="mb-70">
                            <img src={Hall} alt="Hall"/>
                        </figure>

                    </div>
                    {/* /.lqd-column col-md-4 col-xs-12 */}

                    <div className="lqd-column col-md-4 col-xs-12">



                    </div>
                    {/* /.lqd-column col-md-4 col-xs-12 */}

                </div>
                {/* /.row */}
            </div>
            {/* /.container */}
        </section>
    )}else{
        return null
    }
}

export default Boothdetails