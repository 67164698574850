import React from "react";

function BoothBullets({bullet}){

    const myBulletIcon = "icon-et-"+ bullet.bullet_point_icon_key

    return(

            <div className="iconbox iconbox-side iconbox-circle iconbox-sm text-right mb-70">
                <div className="iconbox-icon-wrap">
									<span className="iconbox-icon-container">
										<span className="iconbox-icon-hover-bg bg-dark"></span>
										<i className={myBulletIcon}></i>
									</span>
                </div>
                {/* /.iconbox-icon-wrap */}
                <div className="contents">
                    <h3 className="font-size-15 font-weight-semibold text-primary text-uppercase" dangerouslySetInnerHTML={{__html: bullet.title}}></h3>
                    <p dangerouslySetInnerHTML={{__html: bullet.product_bullet_info}}></p>
                </div>

                {/* /.contents */}
            </div>

    )
}
export default BoothBullets



