import React from "react";

function LiveFeatureBullets({LiveBullet,}) {



    const myLiveBulletClass = "icon-et-" + LiveBullet.bullet_point_icon_key

    return (
        <div className="iconbox iconbox-side iconbox-circle iconbox-sm text-right mb-70">
            <div className="iconbox-icon-wrap">
									<span className="iconbox-icon-container">
										<span className="iconbox-icon-hover-bg bg-dark"></span>
										<i className={myLiveBulletClass}></i>
									</span>
            </div>

            <div className="contents">
                <h3 className="font-size-15 font-weight-semibold text-uppercase text-primary" dangerouslySetInnerHTML={{__html: LiveBullet.title}}></h3>
                <p dangerouslySetInnerHTML={{__html: LiveBullet.product_bullet_info}}></p>
            </div>


        </div>


    )

}

export default LiveFeatureBullets